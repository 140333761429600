import {
  useMutation,
  useQuery as useReactQuery,
  useQueryClient,
} from "react-query";

import FetchClients, { ClientOutput } from "../client";
import { ARCADIA_STATEMENTS_KEY } from "./statements";

export type Rule = ClientOutput["arcadia"]["Rule"];
export type RuleType = ClientOutput["arcadia"]["RuleType"];
export type RuleRequest = ClientOutput["arcadia"]["RuleRequest"];

const rules = {
  useQueryAll,
  usePost,
  useDelete,
  usePatch,
} as const;

export default rules;

export const ARCADIA_RULE_KEY = "arcadia/accounts";

function useQueryAll(arc_meter_ids: string[]) {
  return useReactQuery({
    queryKey: [ARCADIA_RULE_KEY, "all", arc_meter_ids],
    queryFn: async () => {
      const { data, error } = await FetchClients.arcadia.GET("/rules", {
        params: {
          query: {
            arc_meter_ids,
          },
        },
      });
      if (data) return data;
      throw new Error(error);
    },
  });
}

function usePost() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (req: ClientOutput["arcadia"]["RuleRequest"]) => {
      const { data, error } = await FetchClients.arcadia.POST("/rules", {
        body: req,
      });
      if (data) return data;
      throw new Error(error);
    },
    onError: (e) => {
      throw new Error(
        `Unable to create new rule:
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ARCADIA_RULE_KEY, "all"]);
      queryClient.invalidateQueries([ARCADIA_STATEMENTS_KEY]);
    },
  });
}

function useDelete() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (rule_id: number) => {
      const { data, error } = await FetchClients.arcadia.DELETE(
        "/rules/{rule_id}",
        {
          params: {
            path: {
              rule_id,
            },
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onError: (e) => {
      throw new Error(
        `Unable to delete rule:
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ARCADIA_RULE_KEY, "all"]);
      queryClient.invalidateQueries([ARCADIA_STATEMENTS_KEY]);
    },
  });
}

type PatchRuleParams = ClientOutput["arcadia"]["RuleRequest"] & {
  rule_id: number;
};
function usePatch() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ rule_id, ...req }: PatchRuleParams) => {
      const { data, error } = await FetchClients.arcadia.PATCH(
        "/rules/{rule_id}",
        {
          params: {
            path: {
              rule_id,
            },
          },
          body: req,
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onError: (e) => {
      throw new Error(
        `Unable to update rule:
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ARCADIA_RULE_KEY, "all"]);
      queryClient.invalidateQueries([ARCADIA_STATEMENTS_KEY]);
    },
  });
}

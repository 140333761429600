import { useMutation, useQuery, useQueryClient } from "react-query";
import FetchClients, { ClientOutput, ClientPaths } from "../client";

export type StatementType = "BILL" | "NOTICE" | "ADJUSTMENT" | "PREPAID";

// These do not get provided by openapi spec, they must match https://github.com/Bractlet/forty-two/blob/staging/arcadia/src/statements/transform/data_model.rs
export interface TransformError {
  error:
    | "MISSING_REQUIRED_FIELD"
    | "JSON_PARSE_ERROR"
    | "UNEXPECTED_SERVICE_TYPE"
    | "UNEXPECTED_METER_DATA"
    | "MULTIPLE_ACCOUNTS"
    | "MULTIPLE_SITE_MAPPING"
    | "SITE_MAPPING_NOT_FOUND"
    | "NEW_METER_FOUND";
  details?: string;
  path?: string;
}
export type ArcadiaUsage = Partial<{
  calorificValue: number;
  citedUsage: number;
  contributionStatus: "CONTRIBUTING" | "NON_CONTRIBUTING";
  createdAt: string;
  hoursOfUse: number;
  id: string;
  lastModifiedAt: string;
  loadFactor: number;
  loadType: string;
  measuredUsage: number;
  measurementType: string;
  meterConstantMultiplier: number;
  meterConversionMultiplier: number;
  meterReadDate: string;
  meterReadType: string;
  meterReadingDelta: number;
  meterReadingDeltaPrevious: number;
  meterReadingDeltaUsageUnit: string;
  meterReadingRaw: number;
  meterReadingRawPrevious: number;
  numberOfDaysInPeriod: number;
  outageBlock: string;
  periodEndDate: string;
  periodStartDate: string;
  powerFactor: number;
  pressureMultiplier: number;
  prevReadDate: string;
  previousMeterReadType: string;
  previousReadTypeAsPrinted: string;
  previousUsage: number;
  prorationStatus: "PRORATED";
  rateOrTariffNameAsPrinted: string;
  readTypeAsPrinted: string;
  readingSchedule: string;
  tariffRateComponents: [
    {
      tariffName: string;
      tariffRateComponentType: string;
    }
  ];
  usageNameAsPrinted: string;
  usageUnit: string;
}>;

export interface ArcadiaDetailedMeterData {
  amountDue?: number;
  currencyCode?: string;
  meterId: string;
  meterNumber?: string;
  meterReadDate?: string; // YYYY-MM-DD
  normalizedMeterNumber?: string;
  normalizedPointOfDeliveryNumber?: string;
  outstandingBalance?: number;
  pointOfDeliveryNumber?: string;
  previousReadDate?: string; // YYYY-MM-DD
  serviceType?: string;
  serviceTypeClassification: "CORE" | "SUPPLEMENTAL";
  totalCharges?: number;
  totalUsage?: number;
  totalUsageUnit?: string;
  amountDueAfterDueDate?: number;
  amountDueBeforeDueDate?: number;
  bulbQuantity?: number;
  bulbType?: string;
  contributionStatus?: "CONTRIBUTING";
  createdAt?: string;
  customerNumber?: string;
  cycleNumber?: string;
  deregulationStatus?: string;
  disconnectDate?: string;
  discountBeforeDueDate?: number;
  dueDate?: string;
  facilityName?: string;
  facilityNumber?: string;
  generalDescriptionAsPrinted?: string;
  gridPointLineLoss?: number;
  invoiceNumber?: string;
  lastModifiedAt?: string;
  lineLoss?: number;
  mapNumber?: string;
  meterConstantMultiplier?: number;
  meterConversionMultiplier?: number;
  newCharges?: number;
  nextReadDate?: string;
  normalizedPreviousMeterNumber?: string;
  periodEndDate?: string;
  periodStartDate?: string;
  pipeType?: string;
  powerFactor?: number;
  previousBalance?: number;
  previousMeterConstantMultiplier?: number;
  previousMeterNumber?: string;
  rateOrTariffNameAsPrinted?: string;
  readTypeAsPrinted?: string;
  serviceAddress?: {
    addressType?: "FULL";
    city?: string;
    country?: string;
    fullAddress?: string;
    postalCode?: string;
    recipient?: string;
    state?: string;
    streetLine1?: string;
    streetLine2?: string;
  };
  statementDate?: string;
  statementId?: string;
  tariff?: {
    provider?: {
      country?: string;
      id?: string;
      isIntervalDataSupported?: boolean;
      isRealTimeCredentialValidationSupported?: boolean;
      name?: string;
    };
    tariffName?: string;
  };
  usages?: ArcadiaUsage[];
}

export interface ArcadiaDetailedAccountData {
  accountId: string;
  accountNumber: string;
  amountDue?: number;
  normalizedAccountNumber: string;
  outstandingBalance?: number;
  provider: {
    classification: "PUBLISHER" | "PASS_THROUGH";
    id: string;
    name: string;
    publisherProviderAccountId: string;
  };
  totalCharges?: number;
  accountName1?: string;
  accountName2?: string;
  amountDueAfterDueDate?: number;
  amountDueBeforeDueDate?: number;
  billingAddress?: {
    addressType?: "FULL";
    city?: string;
    country?: string;
    fullAddress?: string;
    postalCode?: string;
    recipient?: string;
    state?: string;
    streetLine1?: string;
    streetLine2?: string;
  };
  createdAt?: string;
  currencyCode?: string;
  customerNumber?: string;
  cycleNumber?: string;
  disconnectDate?: string;
  discountBeforeDueDate?: number;
  dueDate?: string;
  facilityName?: string;
  facilityNumber?: string;
  installmentDue?: number;
  installmentOutstanding?: number;
  invoiceNumber?: string;
  mapNumber?: string;
  meterData: ArcadiaDetailedMeterData[];
  newCharges?: number;
  paymentAddress?: {
    addressType?: "FULL";
    city?: string;
    country?: string;
    fullAddress?: string;
    postalCode?: string;
    recipient?: string;
    state?: string;
    streetLine1?: string;
    streetLine2?: string;
  };
  periodEndDate?: string;
  periodStartDate?: string;
  previousBalance?: number;
  statementDate?: string;
  statementId?: string;
  usages?: ArcadiaUsage[];
}

// Should match https://docs.arcadia.com/reference/retrieve-detailed-statement
export interface ArcadiaDetailedStatement {
  amountDue?: number;
  correlationIds: string[];
  createdAt?: string; // string
  currencyCode?: string;
  discoveredAt?: string; // string;
  dueDate?: string; // YYYY-MM-DD
  id: string;
  invoiceNumber?: string;
  normalizedSummaryAccountNumber?: string;
  outstandingBalance?: number;
  periodEndDate?: string; // YYYY-MM-DD
  periodStartDate?: string; // YYYY-MM-DD
  provider?: {
    country?: string;
    id: string;
    isIntervalDataSupported?: boolean;
    isRealTimeCredentialValidationSupported: boolean;
    name: string;
  };
  statementDate?: string; // YYYY-MM-DD
  summaryAccountNumber?: string;
  totalCharges?: number;
  type: StatementType;
  accountData: ArcadiaDetailedAccountData[];
  amountDueAfterDueDate?: number;
  amountDueBeforeDueDate?: number;
  credentialIds?: string[];
  customerNumber?: string;
  cycleNumber?: string;
  disconnectDate?: string;
  discountBeforeDueDate?: number;
  isFinalBill?: boolean;
  lastModifiedAt?: string;
  newCharges?: number;
  previousBalance?: number;
  previousStatementDate?: string;
  summaryAccountId?: string;
  dataIngestionMethod?: string;
  sourceType?: string;
}

const statements = {
  useQueryAll,
  useQueryDetailed,
  usePostTransform,
} as const;

export default statements;

export const ARCADIA_STATEMENTS_KEY = "arcadia/statements";

export type StatementsQuery =
  ClientPaths["arcadia"]["/statements"]["get"]["parameters"]["query"];

export type StatementDetailedResponseAndChanges = Omit<
  ClientOutput["arcadia"]["StatementDetailedResponseAndChanges"],
  "data" | "transform_errors"
> & {
  // `data` is a json blob of the raw detailed statement response from Arcadia.
  // BE does not provide types for it, so we add our own definitions here.
  data: ArcadiaDetailedStatement;

  // This is a little dangerous since transformErrors is actually a json blob,
  // but we are going to assume it's an array of this shape so it's easier to work with.
  // BE should provide types for it in the future.
  transform_errors?: TransformError[];
};

export type StatementChange =
  StatementDetailedResponseAndChanges["changes"][number];

export function getTransformErrorSeverity(): "ERROR" | "WARNING" {
  // In the future, might use a map from error code to severity if we decide some errors should be treated as warnings
  return "ERROR";
}

export type AccountInfoResponse =
  ClientOutput["arcadia"]["AccountInfoResponse"];

export type TransformStatus = ClientOutput["arcadia"]["TransformStatus"];

export type StatementResponse = ClientOutput["arcadia"]["StatementResponse"] & {
  providerName?: string;
};

function useQueryAll(query?: StatementsQuery) {
  return useQuery<StatementResponse[]>(
    [ARCADIA_STATEMENTS_KEY, query],
    async () => {
      const { data, error } = await FetchClients.arcadia.GET("/statements", {
        params: {
          query,
        },
      });
      if (data) return data;
      throw new Error(error);
    }
  );
}

function useQueryDetailed(arc_statement_id: string) {
  return useQuery<StatementDetailedResponseAndChanges>(
    [ARCADIA_STATEMENTS_KEY, arc_statement_id],
    async () => {
      const { data, error } = await FetchClients.arcadia.GET(
        "/statements/{arc_statement_id}",
        {
          params: {
            path: {
              arc_statement_id,
            },
          },
        }
      );
      if (data) return data as StatementDetailedResponseAndChanges;
      throw new Error(error);
    }
  );
}

function usePostTransform(statement_id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      const { data, error } = await FetchClients.arcadia.POST(
        "/statements/{statement_id}/transform",
        {
          params: {
            path: {
              statement_id,
            },
          },
        }
      );
      if (data) return data;
      throw new Error(error);
    },
    onError: (e) => {
      throw new Error(
        `Unable to submit transform for statement ${statement_id}:
        ${(e as Error)?.message || "Unknown Error"}`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries([ARCADIA_STATEMENTS_KEY]);
    },
  });
}
